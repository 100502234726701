.MergerAcquisitionWriteUp{
    padding-top: 100px;
    font-family: 'Poppins', sans-serif;
    width: 100%;
    margin: auto;   
    margin-bottom: 25px;
    position: relative;
}
.MergerAcquisitionWriteUp img{
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    object-fit: cover;
    z-index: 0;
    /* border-radius: 10px; */
}

.ggGoUp{
    width: max-content;
    position: relative;
    margin: 60px auto;
    color: white;
    text-align: center;
    z-index: 10;
}

.MergerAcquisitionWriteUp h1{
    margin-top: 20px;
    text-align: center;
}

.MergerAcquisitionWriteUpList{
    width: max-content;
    margin: 20px auto;
    /* background-color: aqua; */
    /* text-align: center; */
}

.MergerAcquisitionWriteUpList li{
    font-size: 1.3rem;
    line-height: 7vh;
}

@media only screen and (max-width: 750px) {

    .MergerAcquisitionWriteUp h1{
        margin-top: 20px;
        text-align: center;
        font-size: 1.1rem;
    }    

    .MergerAcquisitionWriteUpList li{
        font-size: 0.9rem;
        line-height: 7vh;
    }

    .SubDropPageH1{
        width: 80%;
        margin: auto;
        font-size: 1.8rem;
    }
    .SubDropPageGGGDesP{
        font-size: 0.9rem;
    }
}

@media only screen and (max-width: 425px) {
    .MergerAcquisitionWriteUp h1{
        margin-top: 20px;
        text-align: center;
        font-size: 0.9rem;
    }    

    .MergerAcquisitionWriteUpList li{
        font-size: 0.8rem;
        line-height: 7vh;
    }
}