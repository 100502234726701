.loader{
    background-color: rgb(255, 255, 255);
    height: 100vh;
    position: relative;
    overflow: hidden;
    width: 100vw;
    animation-timing-function: ease;
}

.loaderloader{
    overflow: hidden;
    width: 0vw;
    height: 100vh;
    animation-name: loader;
    position: absolute;
    right: 0;
    background-color: blueviolet;
    animation-iteration-count: calc(2);
    animation-duration: 1s;
    animation-timing-function: ease;
    top: 0;
}

@keyframes loaderbg{
    0%{
        background-color: rgb(255, 255, 255);
        width: 100vw;   
    }

    100%{
        width: 0vw;
        background-color: transparent;
    }
}

@keyframes loader{
    0%{
        overflow: hidden;
        width: 5vw;
        right: 0;
    }
    100%{
        width: 100vw;
        right: 100%;
        background-color: rgb(61, 43, 226);
    }
}