@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@200;400&display=swap');

.startingButton{
    background-color: transparent;
    border-color: transparent;
    /* font-family:'Be Vietnam Pro', sans-serif; */
    font-size: 20;
    font-family: 'Poppins', sans-serif;
    float:right;
    text-align: left;
    transition: text-decoration 0.6s ease;
    text-decoration: 3px underline transparent;
    cursor: pointer;
    padding: 5px;
  }
  
.TiliveInternational{
    margin-right: 30px;
    font-size: large;
    font-weight: lighter;
    font-family: 'Poppins',   sans-serif;
    text-align: right;
}
.start{
  padding: 10px;
  /* height: 90vh; */
}

.startingButton:hover{
    text-decoration: 3px underline rgba(43, 46, 226, 0.904);
}

#startlogo{
  height:90px;
  width: 150px;
  margin-left: 35px;
  object-fit: contain;
  position: fixed;
}
#startlogodummy{
  height: 35px;
  width: 35px;
  position: fixed;
}

.startingPageContainer{
    position: fixed;
    top: 0;
    /* left: 5; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0px 13px;
    margin: auto;
    width: 100%;
    z-index: 99;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
  }

  .startingPageContainerdummy{
    padding: 30px 25px;
    position: absolute;
    top: 0;
    /* left: 5; */
    margin: auto;
    width: 100%;
    z-index: 99;
}

.gTHem{
  color: black;
  font-weight: lighter;
  transform: rotate(90deg);
}

.dropdown{
    background-color:transparent;
}

.dropbtn {
  background-color: transparent;
  color: black;
  /* font-family:'Be Vietnam Pro', sans-serif; */
  font-family: 'Poppins', sans-serif;
  border: none;
}
.navLine{
  margin-right: 10px;
  display: none;
  height: 0vh;
}

/* Style the dropdown content */
.dropdown-content {
  display: none;
  position: absolute;
  z-index: 1;
}

/* Show the dropdown content when the user hovers over the dropdown button */
.dropdown:hover .dropdown-content {
  display: block;
  
}

/* Style the sub-dropdown button */
.sub-dropbtn {
  background-color: transparent;
  color: white;
  padding: 10px;
  text-align: left;
  max-width: 180px;
  border: none;
  font-family: 'Poppins', sans-serif;
  /* font-family:'Be Vietnam Pro', sans-serif; */
}

/* Style the sub-dropdown content */
.sub-dropdown-content {
  display: none;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 100%;
}

/* Show the sub-dropdown content when the user hovers over the sub-dropdown button */
.sub-dropdown:hover .sub-dropdown-content {
  display: block;
}

.sub-dropdown:hover{
    background-color: rgba(0, 0, 0, 0.405);
    /*text-decoration: 3px underline rgb(255, 255, 255);*/
}

.sub-dropdown{
    background-color:rgba(73, 73, 76, 0.46);
    /* font-family:'Be Vietnam Pro', sans-serif; */
    font-family: 'Poppins', sans-serif;
}

.insubdropbtn{
    background-color: transparent;
    color:red;
    text-align: left;
}

.dropdown button{
    outline: none;
    border: none;
}

.insubdropbtn{
  background-color: rgba(0, 0, 0, 0.405);;
  color: white;
  padding: 10px;
  border: none;
  /* font-family:'Be Vietnam Pro', sans-serif; */
  font-family: 'Poppins', sans-serif;
  width:fill;
}

.HomeNav{
  padding: 25px;
  overflow:hidden;
  position: fixed;
  width: 0%;
  right: 0;
  transition: 0.4s;
  display: block;
  background-color: white;
}

.HomeNavClose{
  color: black;
  text-align: right;
}

.HomeNavNav{
  display: flex;
  height: 80vh;
  color: black;
  justify-content: space-evenly;
  margin-bottom: 20px;
  align-items: center;
  flex-direction: column;
  width: 100%;

}

.HomeNavNav a{
  font-family: 'Poppins', sans-serif;
  color: black;
  text-decoration: none;
  /* text-align: left; */
  transition: 1ms;
  text-decoration: 3px none rgba(43, 46, 226, 0.904);
}

.HomeNavNav a:hover{
    text-decoration: 1px underline black;
}

.insubdropbtn:hover{
  background-color: rgba(0, 0, 0, 0.705);
}
#floating_btn{
  position: absolute;
  z-index: 1000;
}

#float_contact{
  bottom: 0;
  right:0; 
  margin: 55px 30px;
  padding: 15px;
  border-radius: 40px;
  position: fixed;
  background-color: rgba(43, 46, 226, 0.904);
  border-color: transparent;
  font-size: small;
  color: white;
  transition: 0.3s;
}

.float_contactfloat_contact{
  background-color: blue;
}

#float_contact:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.616);
  transform: scale(1.1);
}

.float_contactfloat_contact:hover .float_contactfloat_contact{
  animation-name: slide;
  background-color: brown;
  animation-iteration-count: calc(2);
  animation-duration: 5s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}

@keyframes silde{
  0%{
    background-color: rgb(255, 0, 0);
  }
  100%{
    background-color: whitesmoke;
  }
}

@media only screen and (max-width: 700px) {
  .startingPageContainer{
    background-color: white;
    padding-bottom: 30px;
  }

  .TiliveInternational{
    margin-top: 9px;
    font-size: medium;
  }

  #startlogo{
    height: 90px;
    margin-top: 12px;
    margin-left: 15px;
    width: 140px;
  }
  .gg{
    display: none;
  }
  #float_contact{
    margin: 10px;
    padding: 12px;
    font-size: x-small;
  }
  .navLine{
    width: max-content;
    margin: auto;
    float: right;
    margin-right: 10px;
    position: relative;
    color: rgb(255, 255, 255);
    display: block;
  }
}