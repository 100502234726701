.AllNewsHead{
    height: 100vh;
    position: relative;
}

.AllNewsHeadCarousel{
    background-color: rgba(0, 0, 0, 0.959);
    height: 100vh;
    width: 100%;
}

.AllNewsHeadDes{
    width: 85%;
    margin: auto;
    position: absolute;
    z-index: 90;
    color: azure;
    padding-top: 5px;
    top: 43vh;
    /* background-color: aquamarine; */
    display: flex;
    font-family: 'Poppins', sans-serif;
    height: 40vh;
    justify-content: center;
    flex-direction: column;
    left: 8vw;
}

.AllNewsHeadDesHead{
    /* font-family: 'Cinzel', serif; */
    font-size: xx-large;
    max-width: 60%;
    text-align: left;
}

.AllNewsHeadDesP{
    overflow-y: scroll;
    scrollbar-width: 3px;
    -ms-overflow-style: none;
    text-align: left;
    font-size: smaller;
    width: 550px;
}

.AllNewsHeadDesP::-webkit-scrollbar {
    /* display: block; */
    width: 4px;
    height: 4px;
  }

.AllNewsHeadCarousel img{
    object-fit: contain;
    height: 100vh;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.39);
}

.AllNewsHeadCarousel:before {
    content:"";
    position: absolute;
    top:0;
    left:0;
    height:100%;
    width:100%;
    background: rgba(0, 0, 0, 0.582);
    z-index:1;
}


.AllNewsHeadCarouselImg{
    height: 100vh;
    width: 100vw;
}

/* .CarouselFoundHere{
    height: 50vh;
    width: 90vw;
    padding: 0px 15px;
    background-color: rgb(151, 151, 151);
} */



@media only screen and (max-width: 600px) {
    .AllNewsHeadDesHead{
        font-size: smaller;
    }

    .AllNewsHeadDesP{
        font-size: x-small;
        letter-spacing: 1px;
        width: 70%;
    }
}