.ProductsServicesContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.ProductsServicesFund{
    height: 80vh;
    margin: 14vh 0px;
    padding: 0px 30px;
}

.ProductsServicesContainerDes{
    flex: 1;
    font-family: 'Poppins', sans-serif;
}

.ProductsServicesContainerDesP{
    width: 90%;
    /* background-color: aqua; */
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    text-align: center;
}
.ProductsServicesContainerDesHead{
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 30px;
    font-family: 'Poppins', sans-serif;
}
.ProductsServicesContainerImg{
    width: 100%;
    background-color: aqua;
    margin: auto;
    flex: 1;
    height: 60vh;
}

.ProductsServicesContainerDesP{
    font-family: 'Poppins', sans-serif;
    font-size: small;
    letter-spacing: 0.6px;
    /* font-weight: 100; */
    font-weight: 400;
}

.ProductsServicesContainerImg img{
    object-fit: cover;
    height: 60vh;
    width: 100%;
}

/* .ProductsServicesContainerViewInDetail{
    margin: auto;
} */

.ProductsServicesContainerViewInDetail button{
    padding: 7px 12px;
    border: 2px solid rgb(61, 43, 226);
    border-radius: 2px;
    /* width: auto; */
    font-family: 'Poppins', sans-serif;
    background-color: transparent;
}

@media only screen and (max-width: 425px) {
    .ProductsServicesContainerDesHead{
        font-size: large;
    }
    .ProductsServicesContainerDesHead{
        font-size: 1.5rem;
        margin: auto;
        text-align: center;
        margin-bottom: 30px;
    }
    .ProductsServicesContainerImg{
        width: 100%;
        background-color: aqua;
        margin: auto;
        flex: 1;
        height: 50vh;
    }
    .ProductsServicesContainerImg img{
        margin: auto;
        object-fit: cover;
        height: 50vh;
        width: 100%;
    }
    .ProductsServicesContainerInDetail button{
        padding: 3px 9px;
        border: 0.8px solid rgb(61, 43, 226);
        border-radius: 2px;
        /* width: auto; */
        font-family: 'Raleway', sans-serif;
        background-color: transparent;
    }   
    .ProductsServicesContainerDesP{
        text-align: left;
    }
}