@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

.NewsHeading{
    padding: 15px;
    text-align: center;
    text-decoration: underline;
    margin: 30px 0px;
}

.xyz{
    padding: 0;
    width: 26vw;
    height: 380px;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    object-fit: cover;
}
.news {
    position:absolute;
    color: azure;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: none;
    /* justify-content: center; */
    width: 26vw;
    background-color: rgba(0, 0, 0, 0.801);
    height:100%;
    opacity:0;
    text-align: left; 
}

.container{
      /* background-color: blueviolet; */
      /* padding: 5vh 15px; */
      width: 26vw;
      height: 380px;
      margin: auto;
}

.container:hover .news {
    transition: 0.4s;
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity:1;
    font-family: 'Poppins', sans-serif;
}
  
.text {
    word-wrap: break-word;
    width: 260px;
    height:100%;
    display: flex;
    /* transition: 1s; */
   align-items: center;
    /* justify-content: center; */
    text-align: center;
}

.NewsItem{
  /* position: relative; */
  margin: 15px 30px;
  float: left;
  width: 350px;
  height: 350px;
}

.newsDescription{
  padding: 50px 25px;
}
.newsDescriptionHead{
  font-size: medium;
  /* font-family: 'Raleway', sans-serif; */
  font-family: 'Poppins', sans-serif;

  font-weight: 500;
  text-decoration: underline;
}

.newsDescriptionP{
  font-size: small;
  padding: 15px 0px;
  /* font-family: 'Raleway', sans-serif; */
  font-weight: 500;
}

.NewsContainer{
  /* padding: 25px; */
  margin: auto;
  /* position: relative; */
  display: block;
  margin: 50px;
  text-align: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;
}

.NewsContainerHeadHere{
  font-family: 'Poppins', sans-serif;
  margin-left: 50px;
  margin-bottom: 10px;
}

.containerBottom{
  padding: 0%;
  font-size: xx-large;
  position: absolute;
  /* display: none; */
}

.containerimgdiv{
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  font-size: medium;
  flex-direction: column;
  color: black;
}

.AboutUs{
  z-index: -2;
  background-color: rgba(252, 252, 252, 0.993);
}


@media only screen and (max-width: 600px) {
  .NewsContainer{
    /* padding: 25px; */
    margin: auto;
    /* position: relative; */
    display: block;
    margin: 25px;
    text-align: center;
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  .xyz{
    padding: 0;
    width: 80%;
    height: 300px;
    cursor: pointer;
    object-fit: cover;
  }
  
  .container{
      /* background-color: blueviolet; */
      /* padding: 5vh 15px; */
      width: 100%;
      height: 300px;
      margin: auto;
  }
  .container:hover .news {
    display: none;
  }
}