.AutomobileAndEngineeringPartsWriteUp{
    padding-top: 80px;
    width: 90%;
    margin: auto;
    font-family: 'Poppins', sans-serif;    
    margin-bottom: 25px;
}

.AutomobileAndEngineeringPartsWriteUp img{
    height: 80vh;
    width: 100%;
    object-fit: contain;
}