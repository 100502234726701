.ContactUsHome{
    width: 100%;
    font-family: 'Poppins', sans-serif;
    padding: 17vh 15vh 5vh;
}

.ContactUsHomeP{
    font-size: small;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1px;
}

.ContactUsHomeHead{
    font-size: 4rem;    
    font-family: 'Poppins', sans-serif;
    margin-bottom: 20px;
    margin-top:6px;
    line-height: 11vh;
}

.ContactUsHomePara{
    width: 85%;
    font-size: medium;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1px;
    /* background-color: aqua;  */
}

@media only screen and (max-width: 768px) {
    .ContactUsHomeHead {
        font-size: 2.5rem;
    }
    .ContactUsHomePara{
        font-size: small;
    }
}

@media only screen and (max-width: 425px) {
    .ContactUsHome{
        padding: 16vh 6vw 3vh;
    }
    .ContactUsHomeHead {
        line-height: 5vh;
        font-size: 1.7rem;
    }
    .ContactUsHomePara{
        font-size: x-small;
    }
}

@media only screen and (max-width: 385px) {
    .ContactUsHome{
        padding: 15vh 6vw 4vh;
    }
    .ContactUsHomeHead {
        font-size: 1.5rem;
        line-height: 4vh;
      }
      .ContactUsHomePara{
        font-size: xx-small;
    }
}

@media only screen and (max-width: 340px) {
    .ContactUsHomeHead{
        font-size: 1.2rem;
    }
}