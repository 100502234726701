.HomeCareers{
    height:95vh;
    /* width: 100vw; */
    background-color: rgb(255, 255, 255);
    padding: 20vh 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.HomeCareersImgImg{
    margin: auto;
    width: 500px;
    height: 500px;
}

.HomeCareersImg img{
    padding: 80px;
    width: 500px;
    object-fit: cover;
    height: 500px;
    border-radius: 50%;
}

/* .HomeCareersDes{
    flex: 1;
} */

.HomeCareersDesHead4{
    font-size: smaller;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1px;
}

.HomeCareersDesHead2{
    font-style: italic;
    color: whitesmoke;
    background-color: rgb(61, 43, 226);
    width: max-content;
    margin: 10px 0px;
    letter-spacing: 1px;
    font-weight:normal;
    font-size: 2rem;
    border-radius: 2px;
    font-family: 'Poppins', sans-serif;
    padding: 0px 6px;
}

.HomeCareersDesP{
    width: 94%;
    letter-spacing: 0.6px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.HomeProductsDesViewMoreHEy{
    padding: 8px 10px;
    margin-top: 10px;
    width: max-content;
    text-decoration: none;
    display: flex;
    background-color: rgb(61, 43, 226);
    flex-direction: row;
    border-radius: 5px;
    font-family: 'Poppins', sans-serif;
}

.HomeProductsDesViewMoreHEy p{
    font-size: small;
    font-weight: normal;
    text-decoration: none;
    color: whitesmoke;
    font-family: 'Poppins', sans-serif;
}


@media only screen and (max-width: 890px) {
    .HomeCareers{
        flex-direction: column;
        padding: 20px;
    }
    .HomeCareersDesHead2{
        font-size: 1.4rem;
        margin: 10px auto;
    }
    .HomeCareersImgImg{
        height: 300px;
        width: 300px;
    }
    .HomeProductsDesViewMoreHEy{
        margin: 10px auto;
    }
    .HomeCareersImg img{
        width: 300px;
        height: 300px;
        padding: 40px;
    }
    .HomeCareersDes{
        text-align: center;
    }
    .HomeCareersDesP{
        margin: auto;
        width: 95%;
        font-size: 0.7rem;
        letter-spacing: 0.3px;
        font-weight: normal;
    }
}

