.EnLargeNews{
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    position: relative;
    background-size: cover;
    background-image: url("https://media.istockphoto.com/id/846859964/photo/robots-welding-in-a-car-factory.jpg?s=612x612&w=0&k=20&c=YLDHM61cwwC7xPnzP8nlBEbmJLB1sqgMr9W6B1m2WBk=");
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    /* align-items: center; */
    overflow: hidden;
    height: 100vh;
}

.EnLargeNewsImage{
    background-color: black;
    flex: 2;
    /* margin-f: 0%; */
    transition-duration: .7s;
    position: absolute;
    transition-property: initial;
    width: 350px;
    left: 0;
    overflow:auto;
}

.EnLargeNewsImage .carouselFound div{
    display: flex;
}

.EnLargeNewsDescrip{
    /* overflow-y: scroll; */
    height: 100vh;
    overflow: scroll;
    overflow-x: hidden;
    font-family: 'Poppins', sans-serif;
    flex: 8;
    color: white;
    margin-left: 350px;
    background-color: rgba(0, 0, 0, 0.900);
}

.EnLargeNewsHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    font-family: 'Poppins', sans-serif;
    padding: 85px 75px 40px 40px; 
}
.EnLargeNewsHeadingwithshortDes{
    flex: 9;
}
.EnLargeNewsHeadingwithshortDesHead{
    /* font-family: 'Raleway', sans-serif; */
    font-family: 'Poppins', sans-serif;
    font-weight: bolder;
    font-size: xx-large;
    text-decoration: underline;
}
.EnLargeNewsHeadingwithDate{
    flex: 2;
}   
.EnLargeNewsHeadingwithshortDesDes{
    /* font-family: 'Raleway', sans-serif; */
    font-size: medium;
    font-weight: 200;
    word-spacing: 0px;
    font-family: 'Poppins', sans-serif;
}

.EnLargeNewsHeadingwithDate{
    font-family: 'Poppins', sans-serif;
    font-weight: bolder;
    font-size: x-large;
}
.EnLargeNewsBody{
    padding: 25px 40px;
    /* overflow-y: scroll; */
    /* white-space: nowrap; */
}
.EnLargeNewsBodyHead{
    /* font-family: 'Raleway', sans-serif; */
    font-size: x-large;
    font-family: 'Poppins', sans-serif;
    text-decoration: underline;
}
.EnLargeNewsBodyDes{
    padding: 30px 15px 15px;
    /* background-color: blue; */
    font-family: 'Poppins', sans-serif;
}
.EnLargeNewsBodyDesPre{
    letter-spacing: 0.8px;
    /* word-wrap: break-word; */
    white-space: pre-wrap;
    /* background-color: blue; */
    word-spacing: 1.2px;
    /* font-family:'Be Vietnam Pro', sans-serif; */
    font-weight:200;
    font-size: small;
    font-family: 'Poppins', sans-serif;
}

.EnlargeNewsCarouselVidHere video {
    height: 100vh;
    object-fit: contain;
    object-position: center;
} 

/* .EnlargeNewsCarouselVidHere video:before {
    content:"";
    position: absolute;
    top:0;
    left:0;
    height:100%;
    width:100%;
    background: rgba(0, 0, 0, 0.582);
    z-index:1;
} */

.EnLargeNewsBodyPurchaseNow{
    margin-bottom: 2vh;
    width: 100%;
}

.EnLargeNewsBodyPurchaseNowHead{
    margin: auto;
    font-size: smaller;
    font-family: 'Poppins', sans-serif;
    padding: 15px 35px;
    width: max-content;
    background-color: rgba(255, 255, 255, 0.911);
    color: rgba(22, 22, 22, 0.918);
    border-radius: 5px;
}
.bbHEre{
    height: 100vh;
}

/* .bbHEre:before {
    content:"";
    position: absolute;
    top:0;
    left:0;
    height:100%;
    width:100%;
    background: rgba(0, 0, 0, 0.582);
    z-index:1;
} */
@media only screen and (max-width: 1200px) {
    .EnLargeNewsHeadingwithshortDesHead{
        font-size: x-large;
    }.EnLargeNewsBodyHead{
        font-size: medium;
    }
    .EnLargeNewsHeadingwithshortDes{
        max-width: 650px;
    }
    .EnLargeNewsHeadingwithDate{
        flex: 3;
    }
}
@media only screen and (max-width: 950px) {
    .EnLargeNewsHeadingwithshortDesHead{
        font-size: x-large;
    }
    .EnLargeNewsHeadingwithDate{
        font-size: large;
    }
    .EnLargeNewsBodyHead{
        font-size: large;
    }
}

@media only screen and (max-width: 800px) {
    .EnLargeNewsHeadingwithshortDesHead{
        font-size: large;
    }
    .EnLargeNewsHeadingwithDate{
        font-size: medium;
    }
    .EnLargeNewsBodyHead{
        font-size: medium;
    }
    .EnLargeNewsBodyDesPre{
        font-size: .7rem;
    }
}
@media only screen and (max-width: 740px) {
    .EnLargeNewsHeadingwithshortDesHead{
        font-size: medium;
    }
    .EnLargeNewsHeadingwithDate{
        font-size: smaller;
    }
    .EnLargeNewsBodyHead{
        font-size: medium;
    }
    .EnLargeNewsBodyDesPre{
        font-size: .6rem;
    }
}

@media only screen and (max-width: 400px) {
    .EnLargeNewsImage{
        top: 23%;
    }
}
@media only screen and (max-width: 600px) {
    .EnLargeNews{
        flex-direction: column;
    }
    .EnLargeNewsHeader{
        /* background-color: aqua; */
        padding: 6vh 30px;
    }
    .EnLargeNewsHeadingwithshortDesHead{
        max-width: 350px;
    }
    .EnLargeNewsHeadingwithshortDesDes{
        font-size: small;
    }
    .EnLargeNewsImage{
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 21%;
        margin: auto;
        width: 70%;
        height: 150px;
        /* height: 100vh; */
    }
    /* .EnlargeNewsCarouselVidHere video {
        height: 150px;
    }  */
    .EnLargeNewsDescrip{
        margin-left: 0px;
    }
    .bbHEre{
        /* height: 100vh; */
        height: 150px;
    }

    .EnLargeNewsBody{
        padding: 10px 25px;
        margin-top: 27vh;
    }
    .EnLargeNewsBodyDes{
        overflow: scroll;
        height: 40vh;
    }
    .EnlargeNewsCarouselVidHere video{
        height: auto;
    }
}

