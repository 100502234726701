.Fotter{
    padding: 25px 10px;
    position: relative;
    z-index: 100;
    background-color: whitesmoke;
}
.FotterFlex{
    display: flex;
    font-family: 'Poppins', sans-serif;
    font-size: small    ;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.fotterLink{
    text-decoration: none;
    color: rgba(0, 0, 0, 0.904);
}

.fotterLink p{
    margin: 6px 0px;
}

.FotterFlexOne{
    flex: 0.7;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.FotterFlexTwo{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
}

.FotterFlexTwoBranch{
    box-sizing: border-box;
    padding: 20px;
    margin: 10px;
}

.FotterFlexTwoRegistered{
    padding: 20px 30px;
    box-sizing: border-box;
}

.FotterFlexTwoDes{
    width: 100%;
}

.FotterFlexTwoDes a{
    text-decoration: none;
}

.FotterFlexTwoDes p{
    text-align: center;
}

/* . */

@media only screen and (max-width: 600px) {
    .FotterFlexTwoBranch{
        text-align: center;
        margin: 0px;
    }
    .FotterFlexTwoBranch{
        flex: 1;
        font-size: x-small;
    }
    .FotterFlexTwoRegistered{
        font-size: x-small;
        flex: 1;
    }
    .FotterFlex{
        flex: 1;
        flex-direction: column;
        font-size: x-small;
        justify-content: center;
        text-align: center;
    }
    .FotterFlexOne{
        width: 100%;
        flex: 1;
        justify-content: space-around;
    }
}