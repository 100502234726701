.CareersHome{
    height: 100vh;
    position: relative;
    /* background-color: aliceblue; */
}

.CareersHomeImg{
    background-image: url("./image.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    /* box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.49); */
    height: 100vh;
    width: 100%;
}

.CareersHomeImg img{
    height: 110vh;
    width: 100%;
    object-fit: cover;
}

.CareersHomeDes{
    position: absolute;
    top: 46vh;
    font-family: 'Poppins', sans-serif;

    left: 15vw;
    z-index: 10;
}   

.CareersHomeDesInHead{
    color: white;
    /* font-family: 'Raleway', sans-serif; */
    font-size: xx-large;
}


.CareersHomeDesInP{
    font-family: 'Poppins', sans-serif;
    color: white;
}