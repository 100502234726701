.HomeNav{
    height: 100vh;
    padding: 25px;
    position: fixed;
    display: none;
    transition: 0.5s;
    top: 0;
    z-index: 100;
    width: 100%;
    background-color: whitesmoke;
}

.HomeNavClose{
    color: black;
    text-align: right;
}

.HomeNavNav{
    display: flex;
    height: 80vh;
    color: black;
    justify-content: space-evenly;
    margin-bottom: 20px;
    align-items: center;
    flex-direction: column;
    width: 100%;
}