.ProductForm{
    height: 100vh;
    padding: 5px;
    width: auto;
}

.ProductForm label{
    margin-left: 5px;
}   

.ProductFormHead{
    padding: 4vh 0px 2vh;
    text-align: center;
}

.ProductFormFlex{
    display: flex; 
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.newsformeventdes{
    flex: 1;
    padding: 25px;
}

.ProductFormFlexDesCarosel{
    padding: 25px;
}

.newsformdivider{
    margin: 0px 4vw;
}

.ProductFormFlexDesImg{
    width: 25vw;
    height: 7vh;
    padding: 15px;
    outline: none;
}

.ProductFormFlexDestextArea{
    width: 25vw;
    padding: 15px;
    height: 7vh;
    outline: none;
}