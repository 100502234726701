.topNews{
    padding: 60px 0px;
}
.topNewsHead{
    font-size: xx-large;
    font-family: 'Poppins', sans-serif;
    text-align:center;
    margin-bottom: 50px;
    /* font-family: 'Raleway', sans-serif; */
}

.NewsHomeTopNews{
    padding-top: 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    box-sizing: border-box;
}

.NewsHomeTopNewsContent{
    padding: 10px 55px;
    flex: 1;
    margin: auto;
}

.NewsHomeTopNewsImage{
    flex: 1;
}
.NewsHomeTopNewsImageGoesHere{
    width: 25vw;
    margin: auto;
}

.NewsHomeTopNewsImageGoesHere img{
    width: 50vh;
    border-radius: 100%;
    object-fit: cover;
    height: 50vh;
}

.NewsHomeTopNewsImageGoesHere video{
    width: 50vh;
    border-radius: 100%;
    object-fit: cover;
    height: 50vh;
}

.NewsHomeTopNewsContentDesMainHead{
    font-size: xx-large;
    /* font-family: 'Raleway', sans-serif; */    font-family: 'Poppins', sans-serif;

    text-decoration: underline;
}

.NewsHomeTopNewsContentDesHead{
    font-size: smaller;
    margin: 9px 0px;
    padding-left: 0px;
    font-family: 'Poppins', sans-serif;

    /* font-family: 'Raleway', sans-serif; */
}

.NewsHomeTopNewsContentDesP{
    font-size: small;
    /* font-family: 'Raleway', sans-serif; */
    font-family: 'Poppins', sans-serif;
    /* text-align: left; */
    word-spacing: 1px;
}

.NewsHomeTopNewsContentDesViewMore{
    margin: 0   px 40px;
    /* width: 100%; */
    float: left;
    font-family: 'Poppins', sans-serif;
    border-radius: 5px;
    text-align: right;
}

.NewsHomeTopNewsContentDesViewMore button{
    /* font-family: 'Raleway', sans-serif; */
    padding: 8px 16px;
    /* margin-left: 30px; */
    border: 1px solid black;
    /* font-family: 'Cinzel', serif; */
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    background-color: white;
}

@media only screen and (max-width: 600px) {
    .topNewsHead{
        margin-bottom: 10px;
        font-size: x-large;
    }
    .NewsHomeTopNewsContentDesMainHead{
        font-size: x-large;
    }
    .NewsHomeTopNewsContentDesP{
        font-size: small;
        word-spacing: 0px;
    }
    .NewsHomeTopNews{
        flex-direction: column;
        justify-content: space-between;
    }
    .NewsHomeTopNewsContent{
        padding: 10px 15px 0px 15px;
    }
    .NewsHomeTopNewsImageGoesHere{
        width: 30vh;
        height: 30vh;
    }
    .NewsHomeTopNewsContentDesHead{
        font-size: x-small;
        text-align: center;
        margin: 5px 0px 2px;
    }
    .NewsHomeTopNewsContentDesP{
        text-align: center;
    }
    .NewsHomeTopNewsContentDesMainHead{
        font-size: medium;
        text-align: center;
    }
    .NewsHomeTopNewsImageGoesHere img{
        width: 30vh;
        object-fit: cover;
        height: 30vh;
    }
    .NewsHomeTopNewsContentDesViewMore{
        text-align: center;
        width: 100%;
        margin: auto;
    }
    .NewsHomeTopNewsContentDesViewMore button{
        padding: 6px 10px;
        font-size: x-small;
    }
    .NewsHomeTopNewsImageGoesHere video{
        width: 30vh;
        object-fit: cover;
        margin: auto;
        height: 30vh;
    }
}