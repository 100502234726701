.ScrollingComponent{
    display: flex;
    position: relative;
    height: 100vh;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-bottom: 200vh;
    background-color: black ;
}

.ScrollingComponentImg{
    flex: 1;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
}

.ScrollingComponentImg img{
    width: 100%;
    margin: auto;
    height: 100vh;
    position: relative;
    object-fit: cover;
}
.ScrollingComponentImg:before {
    content:"";
    position: absolute;
    top:0;
    left:0;
    height:100%;
    width:100%;
    background: rgba(0, 0, 0, 0.582);
    z-index:1;
}


.ScrollingComponentDes{
    flex: 1;
    z-index: 1000;
    width: 60%;
    box-sizing: border-box;
    margin: auto;
    position: absolute;
    top: 50vh;
    left: 6vw;
    z-index: 10;
    /* background-color: aqua; */
    color: rgb(241, 241, 241);
    /* left: 50%; */
}

.CalledIn{
    position: relative;
    width: 100%;
    background-color: rgb(0, 0, 0);
    /* height: 1; */
}

.ScrollingComponentDesHead{
    /* font-family: 'Raleway', sans-serif; */
    /* font-size: xx-large; */
    letter-spacing: 1px;
}

.ScrollingComponentDesP{
    /* font-family: 'Raleway', sans-serif; */
    letter-spacing: 1px;
    font-size: medium;
}

.ScrollingComponentDesD{
    padding: 0px 25px;
}
.ScrollingComponentViewInDetail{
    padding: 5px 9px;
    /* background-color: blueviolet; */
    color: rgba(238, 238, 238, 0.849);
    width: max-content;
    border: 2px solid rgb(61, 43, 226);
    border-radius: 5px;
}

.ScrollingComponentViewInDetail button{
    font-family: 'Raleway', sans-serif;
    outline: none;
    background-color: transparent;
    color: aliceblue;
    border: none;
}

@media only screen and (max-width: 425px) {
    .ScrollingComponentDesHead{
        font-size: large;
    }
    .ScrollingComponentDesP{
        font-size: small;
    }
}