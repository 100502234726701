.AllNews3Grid{
    padding: 15vh 0vh;
}

.AllNews3GridHead{
    font-weight: 600;
    
    padding: 25px 10px;
    font-family: 'Poppins', sans-serif;
    /* font-family: 'Raleway', sans-serif; */
}

.AllNewsContainer{
    /* padding: 6vh; */
    text-align: center;
    background-color: rgb(255, 255, 255);
    /* margin-bottom: 2vh; */   
}

.AllNewsContainerHead{
    color: rgb(0, 0, 0);
    font-family: 'Poppins', sans-serif;
    /* font-family: 'Raleway', sans-serif; */
}

.AllNewsContainerGrid{
    /* background-color: blueviolet; */
    padding: 5vh 15px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
}

.AllNewsContainerGridEach{
    position: relative;
    margin: auto;
    width: 350px;
    /* transition: 1.4s; */
    height: 350px;
}

.AllNewsContainerGridEach video{
    width: 100%;
    height: 350px;
}

.AllNewsContainerGridEachVid{
    object-fit: cover;
}

.AllNewsContainerGridEach .AllNewsContainerGridEachImg{
    object-fit: cover;
}

.AllNewsContainerGridEachDiv{
    display: none;
    padding: 25px 20px;
    margin: auto;
    width: 350px;
    height: 350px;
    font-family: 'Poppins', sans-serif;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.871);
}

.AllNewsContainerGridEachImg{
    width: 350px;
    height: 350px;
    transition: 0.5s;
}

.AllNewsContainerGridEachImg video{
    width: 350px;
    height: 350px;  
}

.AllNewsContainerGridEachImg img{
    width: 350px;
    height: 350px;  
}

.AllNewsContainerGridEach:hover .AllNewsContainerGridEachDiv{
    top: 0;
    left: 0;
    display: block;
    right: 0;
    bottom: 0%;
}

.AllNewsContainerGridEachDivDes{
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* width: 320px; */
    /* background-color: blue; */
    height: 300px;
    /* padding: 25px 5px 25px 0px; */
}

.AllNewsContainerGridEachDivDesHead{
    text-align: left;
    /* font-family: 'Raleway', sans-serif; */
    font-size: medium;
    /* color: rgba(2, 2, 2, 0.952); */
    color: rgba(255, 255, 255, 0.952);
}

.AllNewsContainerGridEachDivDesP{
    word-wrap: break-word;
    color: rgb(0, 0, 0);
    color: aliceblue;
    /* font-family: 'Raleway', sans-serif; */
    text-align: left;
}

/* @keyframes slower{
    100%{
        tr
    }
} */


@keyframes slower {
    100%{
        display: block;
    }
}

@media only screen and (max-width: 800px) {
    .AllNewsContainerGrid{
        padding: 2vh 10px;
        display: grid;
        grid-template-columns: 1fr;
        gap: 4rem;
    }
    
    .AllNewsContainerGridEach:hover .AllNewsContainerGridEachDiv{
        display: none;
    }
    
    .AllNewsContainerGridEachDivDes{
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* width: 320px; */
        /* background-color: blue; */
        height: 300px;
        /* padding: 25px 5px 25px 0px; */
    }
    
    .AllNews3GridHead{
        font-size: large;
    }

    .AllNewsContainerGridEachDiv{
        display: none;
        padding: 25px 20px;
        margin: auto;
        width: 300px;
        height: 300px;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.871);
    }

    .AllNewsContainerGridEach{
        position: relative;
        margin: auto;
        width: 300px;
        /* transition: 1.4s; */
        height: 300px;
    }
    
    .AllNewsContainerGridEach video{
        width: 100%;
        height: 300px;
    }
    .AllNewsContainerGridEachImg{
        width: 300px;
        height: 300px;
        transition: 0.5s;
    }
    
    .AllNewsContainerGridEachImg video{
        width: 300px;
        height: 300px;  
    }
    
    .AllNewsContainerGridEachImg img{
        width: 300px;
        height: 300px;  
    }
}