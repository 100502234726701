.ContactUsForm{
    width: 100%;    font-family: 'Poppins', sans-serif;
    
    padding: 5vh 15vh;
}

.handleCapture{
    padding: 2vh 0px;
    padding-bottom: 10vh;
}

.handleCaptureForm{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    padding: 0vh 0px 5vh;
}
.handleCaptureFormNameInp{
    width: 30vw;
    font-family: 'Poppins', sans-serif;
    padding: 20px;
}
.handleCaptureFormLeft input{
    outline: none;
    font-family: 'Poppins', sans-serif;

    border: 0.1px solid rgba(0, 0, 0, 0.712);
}

.handleCaptureFormRight textarea{
    padding: 10px;
    width: 40vw;
    background-color: transparent;
    font-family: 'Poppins', sans-serif;

    border: 1px solid rgba(0, 0, 0, 0.692);
    outline: none;
}

.handleCaptureFormNamep{
    font-size: small;
    margin-bottom: 5px;
    font-family: 'Poppins', sans-serif;

    font-weight: 400;
    letter-spacing: 1px;
}

.handleCaptureFormMailp{
    /* color: blueviolet; */
    font-size: small;
    margin-bottom: 5px;
    font-weight: 400;
    letter-spacing: 1px;
    font-family: 'Poppins', sans-serif;

}
.handleCaptureFormNump{
    font-family: 'Poppins', sans-serif;

    font-size: small;
    /* color: blueviolet; */
    margin-bottom: 5px;
    font-weight: 400;
    letter-spacing: 1px;

}
.handleCaptureFormTextAreaP{
    font-family: 'Poppins', sans-serif;

    font-size: small;
    /* color: blueviolet; */
    margin-bottom: 5px;
    font-weight: 400;
    letter-spacing: 1px;
}
.handleCaptureFormTextArea textarea{
    resize: none;
}

.handleCaptureFormMailInp{
    width: 30vw;
    padding: 20px;
}

.handleCaptureFormNumInp{
    width: 30vw;
    padding: 20px;
}

.handleCaptureFormSubmit{
    padding: 10px 45px;
    border: 2px solid rgb(61, 43, 226);
    width: max-content;
    margin: 0px 25vh;
    font-family: 'Poppins', sans-serif;
    background-color: rgb(61, 43, 226);
}

.handleCaptureFormSubmitButton{
    background-color: transparent;
    font-family: 'Poppins', sans-serif;
    outline: none;
    border: none;
    color: white;
}

.handleCaptureFormText{
    display: flex;
    justify-content: center;
    width: 100%;
}

.handleCaptureFormText p{
    font-family: 'Poppins', sans-serif;
    font-size: x-small;
    width: 80%;
    margin: 25px;
}

@media only screen and (max-width: 768px) {
    .handleCapture{
        padding: 5vh;
    }
    .handleCaptureForm{
        padding: 3vh 0px 1vh;
    }
    .handleCaptureFormNamep{
        font-size: x-small;
    }
    .handleCaptureFormMailp{
        font-size: x-small;
    }
    .handleCaptureFormTextAreaP{
        font-size: x-small;
    }
    .handleCaptureFormNump{
        font-size: x-small;
    }
    .handleCaptureFormSubmit{
        margin: 20px 60px;
    }
}


@media only screen and (max-width: 768px) {
    .handleCapture{
        /* background-color: aqua; */
        padding: 3vh 10px;
    }
    .handleCaptureForm{
        padding: 3vh 0px;
    }
    .handleCaptureFormNameInp{
        width: 35vw;
        padding: 10px;
    }
    .handleCaptureFormMailInp{
        width: 35vw;
        padding: 10px;
    }
    .handleCaptureFormNumInp{
        width: 35vw;
        padding: 10px;
    }
    .handleCaptureFormNamep{
        font-size: xx-small;
    }
    .handleCaptureFormMailp{
        font-size: xx-small;
    }
    .handleCaptureFormTextAreaP{
        font-size: xx-small;
    }
    .handleCaptureFormNump{
        font-size: xx-small;
    }
    .handleCaptureFormSubmit{
        margin: 20px 30px;
        padding: 10px 40px;
    }
    .handleCaptureFormTextArea textarea{
        /* background-color: brown; */
        height: 35vh;
    }
}

@media only screen and (max-width: 425px) {
    .handleCaptureFormText p{
        /* font-family: 'Poppins', sans-serif; */
        font-size: xx-small;
        margin: 0px 25px;
        width: 90%;
    }
    .handleCaptureFormSubmit{
        margin: auto;
        padding: 10px 50px;
        border-radius: 2px;
    }
    .handleCapture{
        width: 100%;
        padding: 1vh 10px;
    }
    .handleCaptureFormLeft{
        width: 100%;
    }
    .handleCaptureFormRight{
        margin-top: 20px;
    }
    .handleCaptureForm{
        flex-direction: column;
        /* background-color: ; */
        padding: 20px 25px;
    }
    .handleCaptureFormNameInp{
        width: 100%;
    }
    .handleCaptureFormMailInp{
        width: 100%;
    }
    .handleCaptureFormNumInp{
        width: 100%;
        width: 100%;
    }
    .handleCaptureFormTextArea textarea{
        width: 100%;
    }
}
@media only screen and (max-width: 375px) {
    .handleCaptureFormTextArea textarea{
        /* background-color: brown; */
        height: 33vh;
    }
}

@media only screen and (max-width: 340px) {
    .handleCaptureFormNump{
        font-size: 8px;
        letter-spacing: 0px;
    }
    .handleCaptureFormNamep{
        font-size: 8px;
        letter-spacing: 0px;
    }
    .handleCaptureFormMailp{
        font-size: 8px;
        letter-spacing: 0px;
    }
    .handleCaptureFormTextAreaP{
        font-size: 8px;
        letter-spacing: 0px;
    }
}