
.SubDropPageGGG{
    margin-left: auto;
    /* margin-bottom: 20px; */
    margin-right: auto;
}

.iframe{
    width: 100%;
    /* background-color: aqua; */
    height: 290px;
}

.iframe iframe{
    width: 100%;
    height: 290px;    
}

@media only screen and (max-width: 600px) {

    .iframe{
        margin: auto;
        width: 100%;
        height: 200px;
        /* background-color: aqua; */
    }
    .iframe frame{
        width: 390px;
        /* object-fit: contain; */
        height: 100px;
    }

}