.CompanySecret{
    position: relative;
    height: 100vh;
    /* background: red; */
    /* width: 100%; */
    margin: 10vh 0px;
    padding: 10vh 0vh;
}

.CompanySecretBG img{
    /* border-radius: 100v; */
    height: 80vh;
    object-fit: cover;
    z-index: -1;
    width: 100%;
}
.CompanySecretHead{
    /* padding: 50px 0vh; */
    text-align: center;
    font-size: large;
    font-family: 'Poppins', sans-serif;
    
    color: rgb(61, 43, 226);
    padding: 45px 10px;
    text-decoration: underline;
    position: relative;
    z-index: 1;
}
.CompanySecretHeadH1{
    /* font-family: 'Raleway', sans-serif; */
    font-weight: 400;
}
.CompanySecretDes{
    position: relative;
    /* border: 1px solid blueviolet; */
    font-family: 'Poppins', sans-serif;
    padding: 10vh 35px;
    /* font-family: 'Raleway', sans-serif; */
    /* letter-spacing: 1px; */
    font-size: medium;
    color: rgb(0, 0, 0);
    text-align: center;
    z-index: 1;
}

@media only screen and (max-width: 600px) {
    .CompanySecretDes{
        width: 90%;
        padding: 5vh 10px;
        margin: auto;
        font-size: small;
    }
    .CompanySecretHead{
        font-size: medium;
    }
}