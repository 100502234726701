.NewsHome{
    height: 130vh;
    overflow: hidden;
    width: 100%;
    background-image: url("https://www.cmc-consultants.com/hubfs/CMC-blog-taiwan.jpg");
    background-position: center;
    background-repeat: no-repeat;
    object-fit: cover;
    -webkit-box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.39);
    -moz-box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.39);
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.558);
    padding-bottom: 100px;
}
.NewsHomeDes{
    position: relative;
    top: 50vh;
    width: 60%;
    left: 5vw;
}

.NewsHomeDesHead{
    color: white;
    font-size: xx-large;
    font-family: 'Poppins', sans-serif;

    /* font-family: 'Raleway', sans-serif; */
}
.NewsHomeDesP{
    color: white;
    font-size: smaller;
    font-family: 'Poppins', sans-serif;
    
    letter-spacing: 1px;
    /* font-family: 'Raleway', sans-serif; */
}

@media only screen and (max-width: 425px) {
    .NewsHomeDesHead{
        font-size: x-large;
    }
    .NewsHomeDesP{
        font-size: x-small;
    }
}