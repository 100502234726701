.newsform{
    height: 100vh;
}

.newsformPre{
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12vh 0px 20vh;
}

.newsformHead{
    text-decoration: underline;
    font-size: x-large;
}

.NewForminputButton{
    border-radius: 2px;
    padding: 25px;
    margin-top: 10px;
    background-color: rgb(61, 43, 226);
    border: none;
    color: white;
    width: 100%;
}

.NewForminput{
    outline: none;
    /* border: none; */
    font-size: medium;
    width: 25vw;
    height: 6vh;
    padding: 25px;
}
.newsformPreP{
    /* background-color: aqua;   */
    text-align: left;
    padding: 25px 30px 15px;
}

.newsformhead{
    text-align: center;
    /* font-family: 'Cinzel', serif; */
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    padding: 40px 0px;
    font-size: large;
}
.newsformdivider{
    height: 70px;
    width: 2px;
    margin-top: 50px;
    background-color: black;
}
.newsformformflex{
    display: flex; 
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
}
.newsformeventdes{
    text-align: center;
    flex: 1;
    font-family: 'Poppins', sans-serif;
}

.newsFormLabel{
    margin-left: 10px;
}

.newsformeventdesp{
    font-size: smaller;
    /* font-family:'Be Vietnam Pro', sans-serif; */
    font-weight: 100;
    text-align: left;
    padding-left: 10px;
    margin-bottom: 8px;
}
.newsformimg{
    text-align: center;
    /* margin-top: 10px; */
    flex: 1;
}

.newsformeventdesEventName{
    width: 380px;
    margin: auto;
    margin-bottom: 40px;
}
.newsformeventdesEventCap{
    width: 380px;
    margin: auto;
    margin-bottom: 40px;
}
.newsformeventdesEventDes{
    width: 380px;
    margin: auto;
    margin-bottom: 40px;
}
.newsformeventdesinput{
    width: 100%;
    height: 40px;
    outline: none;
    padding-left: 5px;
    /* border: 0.1px solid black; */
}

.newsformbutton{
    margin: auto;
    width: 25%;
    /* margin-bottom: 5vh; */
}
.newsformbuttonhead{    
    outline: none;
    border: none;
    background: transparent;
    padding: 15px 20px;
    width: 100%;
    text-align: center;
    margin: auto;
    color: white;
    font-size: large;
    font-family: 'Cinzel', serif;
    font-weight: 100;
}

.newsformbuttonhead p {
    padding: 15px 35px;
    width: max-content;
    margin: auto;
    background: rgb(61, 43, 226);
}

.newsformimgCarousel{
    width: 100%;
    height: 200px;
    /* background-color: black; */
    text-align: center;
    /* background-color: aqua; */
}
.newsformimgCarouselIn{
    margin:auto;
    width: 350px;
    /* background-color: rgb(245, 245, 245); */
    /* max-height: 100px; */
    /* width: 150px; */
}
.newsformimgCarouselImg{
    max-height: 200px;
    /* height: 220px; */
    object-fit: contain;
}


@media only screen and (max-width: 600px) {
    .newsformImg{
        width: 300px;
        height: 150px;
    }
    .NewForminput{
        width: 60vw;
    }
}