.CarrersScrollList{
    height: 100vh;
    position: relative;
    background-color: aquamarine;
}

.CarrersScrollListImg{
    width: 100%;
    height: 100vh;  
    top: 0;
    left: 0;  
    position: absolute;
}

.CarrersScrollListImgDes{
    position: absolute;
    width: max-content;
    font-family: 'Poppins', sans-serif;

    left: 0;
    color: white;
    background-color: rgba(0, 0, 0, 0.603);
    border-radius: 10px;padding: 10px 25px;
    text-align: center;
    right: 0;
    margin: 0 auto;
}

.CarrersScrollListImgDesFixed{
    width: 100%;
    height: 100vh;  
    top: 0;
    left: 0;  
    position: fixed;
}

.CarrersScrollListImgDesFixed img{
    width: 100%;
    height: 100vh;
    /* position: fixed; */
    object-fit: cover;
}

.CarrersScrollListImgDesHead{
    font-family: 'Poppins', sans-serif;
}

.CarrersScrollListImg img{
    width: 100%;
    height: 100vh;
    /* position: fixed; */
    object-fit: cover;
}

.CarrersScrollListImgDesP{
    color: white;
    /* font-family: 'Raleway', sans-serif; */
    width: 60vw;
}

.CarrersScrollListImgDesLocationP{
    padding: 6px 5vw;
    width: max-content;
    border-radius: 2px;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.884);
}