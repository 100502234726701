.AllNewsCreativeContainer{
    /* padding: 0vh 8vh; */
    /* height: 97vh; */
    /* margin: 30px 30px; */
    /* box-shadow: 0px 0px 20px rgb(199, 199, 199); */
}

.AllNewsContainerStartsHere{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    /* align-items: center; */
    justify-content: space-evenly;
}

.AllNewsContainerStartsHereDes{
    flex: 1;    font-family: 'Poppins', sans-serif;

    /* text-align: left; */
    background-color: rgb(255, 255, 255);
}

.AllNewsContainerStartsHereImg{
    flex: 1;
    /* background-color: brown; */
}
.AllNewsContainerStartsHereDesHead{
    text-align:center;
    padding-top: 10px;
    /* font-family: 'Raleway', sans-serif; */
    font-size: xx-large;
    font-family: 'Poppins', sans-serif;
    text-decoration: underline;
}

.AllNewsContainerStartsHereImg img{
    width: 300px;
    height: 70vh;
    background-color: blue;
    object-fit: cover;
}

.AllNewsContainerStartsHereImg video{
    width: 300px;
    height: 70vh;
    object-fit: cover;
}

.AllNewsContainerStartsHereDesP{
    /* padding: 25px 25px; */
    width: 85%;
    margin: auto;
    font-size: medium;
    letter-spacing: 1px;
    word-spacing: 2px;
    text-align: center;
    font-family: 'Poppins', sans-serif;

    /* font-family: 'Raleway', sans-serif; */
}

@media only screen and (max-width: 425px) {
    .AllNewsContainerStartsHereDesHead{
        font-size: large;
    }

    .AllNewsContainerStartsHereDesP{
        font-size: x-small;
        letter-spacing: 0px;
        text-align: left;
        padding: 10px 0px;
    }

    .AllNewsContainerStartsHereImg img{
        width: 100px;
        height: 40vh;
        object-fit: cover;
    }
    
    .AllNewsContainerStartsHereImg video{
        width: 100px;
        height: 40vh;
        object-fit: cover;
    }
    
    .AllNewsCreativeContainer{
        height: 65vh;
        padding: 3vh 2vh;
        margin: 30px 40px;
        box-shadow: 0px 0px 20px rgb(199, 199, 199);
    }
}