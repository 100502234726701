.HomePage{
    height: 100vh;
    /* width: 100vw; */
    /* width: auto; */
    position: relative;
    z-index: 10;
    scrollbar-width: 1px;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.39);
}

.downScroll{
    position: absolute;
    z-index: 100;
    margin-bottom: 65px;
    bottom: 0;
    transition: 1ms;
    text-align: center;
    width: 100%;
}

.downScroll a{
    color: whitesmoke;
    text-decoration: underline;
    border-radius: 9px;
    padding: 6px 12px;
    margin-bottom: 10px;
    margin: auto;
}

.HomePageCarousel{
    width: auto;
    height: 100vh;
}
.hnhn {
    position: relative;
    z-index: 100;
    height: 100vh;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.408); /* Adjust the background color and opacity as desired */
    z-index: 0;
}

.hnhn img{
    height: 100vh;
    object-fit:cover;
}

.hnhn img{
    background-color: #0000008f;
}

.HomePageDes{
    /* height: 100vh; */
    z-index: 100;
    position: absolute;
    display: block;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 60%;
    height: 100vh;
    left: 10%;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
}   

.HomePageDesHead{
    color: rgb(248, 248, 248);
    /* width: 60%; */
    line-height: 60px;
    font-size: 2.7rem;
    font-family: 'Poppins', sans-serif;
    /* margin-bottom: 8px; */
}

.aaaa{
    width: max-content;
    display: block;
    text-decoration: none;
}

.HomePageDesP{
    width: 100%;
    color: rgb(247, 247, 247);
    font-size: 0.9rem;
    margin-bottom: 20px;
    letter-spacing: 1px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
}

.HomePageDesPP{
    width: 100%;
    color: rgb(241, 241, 241);
    font-size: 1rem;
    /* background-color: aliceblue; */
    margin-bottom: 20px;
    letter-spacing: 1px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
}

.HomeProductsDesViewMore{
    padding: 15px 25px;
    border-radius: 6px;
}

@media only screen and (max-width: 605px) {
    .HomePageDesHead{
        font-size: 2rem;
        line-height: 45px;
    }
    .HomePageDesP{
        letter-spacing: 2px;
        font-weight:lighter;
    }
    .HomePageDesPP{
        font-weight:400;
        letter-spacing: 0px;
    }
    .HomePage{
        height: 100vh;   
    }

    .hnhn{
        height: 100vh;
    }

    .hnhn img{
        height: 100vh;
    }

    .HomePageCarousel{
        height: 100vh;
    }
    .HomePageDesPP{
        width: 100%;
        color: rgb(252, 252, 252);
        font-size: 0.6rem;
        margin-bottom: 20px;
        letter-spacing: 1px;
        font-weight: 500;
        font-family: 'Poppins', sans-serif;
    }
    .HomePageDes{
        width: 80%;
        top: 20%;
        left: 10%;
    }
    .HomePageDesP{
        font-size: 0.6rem;
    }
}
