.ProductsServicesHomeHead{
    height: 100vh;
    background-color: rgb(61, 43, 226);
    display: flex;
    font-family: 'Poppins', sans-serif;
    position: relative;
    flex-direction: row;
}

.ProductsServicesHomeHeadImg{
    height: 100vh;
    width: 100%;
    background-color: white;
}

.ProductsServicesHomeHeadImg img{
    height: 100vh;
    width: 100%;
    object-fit: cover;
}

.ProductsServicesHomeHeadDes{
    position: absolute;
    width: 60vw;
    top: 40vh;
    left: 10vw;
    font-family: 'Poppins', sans-serif;
}

.ProductsServicesHomeHeadDes h2{
    color: whitesmoke;
    font-family: 'Poppins', sans-serif;
    font-style: italic;
    font-size: xx-large;
    text-decoration: underline;
}
