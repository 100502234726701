.SubDropPage{
    height: 100vh;
    /* background-color: rgb(36, 36, 36);
    color: aliceblue; */
    padding-top: 120px;
    font-family: 'Poppins', sans-serif;
}
.SubDropPageH1{
    text-align: center;
}

.SubDropPageDiv{
    width: 80%;
    margin: auto;
    padding: 25px 10px;
}