.ClientsWhyUs{
    /* height: 110vh; */
    background-color: rgb(209, 0, 0);
    margin: 8vh auto;
    /* width: 100%; */
}

.ClientsWhyUsHead{
    font-size: 50px;
    color: black;
    font-family: 'Poppins', sans-serif;
    /* font-family: 'Raleway', sans-serif; */
}

.ClientsWhyUsPPBold{
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
}

.ClientsWhyUsPIn{
    padding: 18vh 0px;
    width: 70%;
    margin: auto;
}

.ClientsWhyUsPP{
    /* font-family: 'Raleway', sans-serif; */
    letter-spacing: 1px;
    font-size: medium;
    /* font-weight: 600; */
    /* word-spacing: 1px; */
    color: rgba(0, 0, 0, 0.938);
}
@media only screen and (max-width: 600px) {
    .ClientsWhyUsHead{
        font-size: 1.5rem;
    }   
}
@media only screen and (max-width: 500px) {
    .ClientsWhyUsHead{
        font-size: 1rem;
    }
    .ClientsWhyUsPIn{
        width: 80%;
    }
    
    .ClientsWhyUsPP{
        font-size: small;
        text-align: center;
    }
}