.AboutUsHome{
    background-image: url("https://images.businessnewsdaily.com/app/uploads/2022/04/04074130/teamwork_g-stockstudio_getty.jpg");
    height: 135vh;
    /* position: relative; */
    width: 100%;
    overflow: hidden;
    /* background-color: violet; */
    background-position:center;
    background-repeat: no-repeat;
    background-color: aqua;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.39);
    object-fit: cover;
    z-index: 1;
    /* object-fit: cover;    */
}

.AboutUsHomeDes{
    position: absolute;
    width: 100%;
    text-align: center;
    /* top: 35vh; */
    font-size: large;
    padding: 0px 25px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    color: white;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    animation-name: textUnderline;
    animation-timing-function: ease;
    animation-delay: 2s;
}

.AboutUsHomeDesHead{
    /* background-color: blue; */

}

.AboutUsHomeDesHead .x{
    /* padding: 10px 5px 20px; */
    font-size: large;
    /* margin-bottom: 10px; */
    /* background-color: aqua; */
}

@keyframes textUnderline {
    0%{
        text-decoration: none;
    }100%{
        text-decoration: underline;
    }
}

@media only screen and (max-width: 950px) {
    .AboutUsHomeDesHead{
        font-size: x-large;
    }
}

@media only screen and (max-width: 650px) {
    .AboutUsHomeDesHead{
        font-size: large;
    }
}