.ClientsHome{
    height: 100vh;
    background-image: url("https://www.sedex.com/app/uploads/2022/11/Consulting-2.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.589);
}

.ClientsHomeImg{
    position: relative;
    height: 100vh;
}

.ClientsHomeImg img{
    height: 100vh;
    object-fit: cover;
    width: 100%;
}

.ClientsHomeDes{
    position: absolute;
    font-family: 'Poppins', sans-serif;
    top: 35vh;
    left: 8vw;
}

.ClientsHomeDesHeadThird{
    color: aliceblue;
    font-family: 'Poppins', sans-serif;
    font-size: small;
    font-weight: 600;
    letter-spacing: 1px;
}

.ClientsHomeDesHeadOne{
    font-family: 'Poppins', sans-serif;
    color: rgb(255, 255, 255);
    /* color: antiquewhite; */
    padding: 0px 8px;
    margin: 0%;
    background-color: rgb(61, 43, 226);
    width: max-content;
    font-size: larger;
    font-style: italic;
    font-size: 30px;
    font-weight: 200;
}

.ClientsHomeDesP{
    font-weight: 600;
    width: 70%;
}

.ClientsHomeDesPSpan{
    font-size: small;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    letter-spacing: 1px;
    word-spacing: 1px;
    color: azure;
}

@media only screen and (max-width: 700px) {
    .ClientsHomeDesHeadOne{
        font-size: 1rem;
        /* word-wrap: break-word; */
    }
    .ClientsHomeDes{
        width: 90%;
    }
    .ClientsHomeDesP{
        width: 90%;
    }
    .ClientsHomeDes{
        top: 25vh;
    }
    .ClientsHomeDesPSpan{
        font-size: small;
    }
}