.MarketingBusinessWriteUp{
    padding-top: 100px;
    font-family: 'Poppins', sans-serif;
    width: 100%;
    margin: auto;   
    margin-bottom: 25px;
}
.MarketingBusinessWriteUp img{
    height: 90vh;
    width: 100%;
    /* object-fit: contain; */
    border-radius: 10px;
}
.MarketingBusinessWriteUpHead{
    padding-left: 25px;
}

.iframe{
    width: 100%;
    /* background-color: aqua; */
    height: 290px;
}

.iframe iframe{
    width: 100%;
    height: 290px;    
}

@media only screen and (max-width: 425px) {
    .MarketingBusinessWriteUp img{
        object-fit: contain;
        height: 40vh;
        width:100%;
    }
    .SubDropPageH1{
        width: 90%;
        margin: auto;
        text-align: left;
        font-size: 1.7rem;
    }
    .SubDropPageGGGDesP{
        font-size: 0.5rem;
    }
    .MarketingBusinessWriteUpHead{
         /* font-size: 1.5rem; */
         line-height: 6vh;
    }

    .iframe{
        margin: auto;
        width: 100%;
        height: 200px;
        /* background-color: aqua; */
    }
    .iframe frame{
        width: 390px;
        /* object-fit: contain; */
        height: 100px;
    }
}