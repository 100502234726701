.SubDropPageforMarketing{
    padding-top: 120px;
    height: 100vh;
    margin: auto;
    font-family: 'Poppins', sans-serif;
    width: 98%;
}

.SubDropPageGGG{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;   
}

.SubDropPageGGGDes{
    box-sizing: border-box;
    width: 50%;
    padding: 20px;
}

.CarouselForMarketBusiness{
    width: 40%;
    /* flex: 1; */
    margin: auto;
}
.CarouselForMarketBusiness img{
    height: 300px;
    object-fit: cover;
}

.MarketingBusinessWriteUpViewMore{
    padding: 10px 25px;
    color: white;
    border-radius: 6px;
    width: max-content;
    background-color: rgb(0, 4, 255);
}


.iframe{
    width: 100%;
    /* background-color: aqua; */
    height: 290px;
}

.iframe iframe{
    width: 100%;
    height: 290px;    
}
@media only screen and (max-width: 600px) {
    .SubDropPageforMarketing{
        padding-top: 0px;
        margin-top: 60px;
        margin-top: 80px;
    }
    
    .SubDropPageGGG{
        flex-direction: column;
    }
    .SubDropPageGGGDes{
        width: 100%;
    }
    .CarouselForMarketBusiness{
        width: 90%;
        height: 150px;
    }
    .CarouselForMarketBusiness img{
        object-fit: contain;
        height: 150px;
    }
    .SubDropPageH1{
        width: 95%;
        font-size: large;
    }
    .MarketingBusinessWriteUpViewMore{
        padding: 7px 15px;
        font-size: smaller;
    }

    .iframe{
        margin: auto;
        width: 100%;
        height: 200px;
        /* margin-bottom: 20px; */
        /* background-color: aqua; */
    }
    .iframe frame{
        width: 390px;
        /* object-fit: contain; */
        height: 100px;
    }
}
