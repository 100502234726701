.HomeProducts{
    height: 100vh;
    background-color: aqua;
    position: relative;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.541);
    background-image: url("https://www.livemint.com/lm-img/img/2023/04/11/1600x900/2-0-131573390-Tata-Steel-4C-0_1681212533240_1681212948573_1681212948573.jpg");
}

.HomeProductsDes{
    width: 44%;
    position: absolute;
    top: 50%;
    left: 13%;
    color: whitesmoke;
}

.HomeProductsDesHead4{
    font-size: 0.7rem;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1px;
}

.HomeProductsDesHead2{
    font-size: 2.7rem;
    font-style: italic;
    font-family: 'Poppins', sans-serif;
    margin: 6px 0px;
}

.HomeProductsDesP{
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1px;
    font-size: smaller;
    font-weight: 400;
}

.HomeProductsDesViewMore{
    padding: 8px 10px;
    margin-top: 10px;
    width: max-content;
    text-decoration: none;
    display: flex;
    background-color: rgb(61, 43, 226);
    flex-direction: row;
    border-radius: 5px;
}

.HomeProductsDesViewMore p{
    font-size: small;
    font-weight: normal;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
    color: whitesmoke;
}

.aaa{
    width: max-content;
    background-color: aqua;
    text-decoration: none;
    color: rgb(61, 43, 226);
}

@media only screen and (max-width: 425px) {
    .HomeProductsDes{
        width: 60%;left: 10%;
    }
    .HomeProductsDesHead4{
        font-size: 0.6rem;
    }
    .HomeProductsDesViewMore{
        padding: 5px 9px;
    }
    .HomeProductsDesViewMore p{
        font-size: x-small;
    }
    .HomeProductsDesP{
        font-size: x-small;
    }
    .HomeProductsDesHead2{
        font-size: 1.5rem;
    }
    .aaa{
        font-size: smaller;
    }
}