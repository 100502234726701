.WeAre{
    height: 110vh;
    padding: 100px 0px;
    background-color: rgb(253, 253, 253);
}

.WeAreHead{
    margin-bottom: 20px;
    text-align: center;
    font-size: xx-large;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    color: black;
}
.WeAreMainContent{
    text-align: center;
    position: relative;
}

.WeAreMainContentImg{
    width: 220px;
    height: 220px;
    margin: auto;
}

.WeAreMainContentImg img{
    border-radius:110px;
    z-index: 10;
    position: relative;
    width: 220px;
    /* top: -9px; */
    height: 220px;
    object-fit: cover;
    border: 10px solid rgb(253, 253, 253);
}

.WeAreMainContentDes{
    position: relative;
    top: -100px;
    z-index: 1;
    margin: auto;
    width: 95%;
    /* height: 50vh; */
    padding: 16vh 10vh 5vh;
    border-radius: 3px;
    border: 1px solid black;
}

.WeAreMainContentDes p{
    font-size: 16px;
    color: rgb(0, 0, 0);
    /* font-weight: 3px; */
    font-size: larger;
    /* letter-spacing: 1px; */
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}

@media only screen and (max-width: 600px) {
    .WeAreMainContentDes{
        padding: 17vh 20px 4vh;
        /* border: none; */
        top: -70px;
    }
    .WeAreHead{
        margin-bottom: 20px;
    }
    .WeAre{
        height: 90vh;
        padding: 55px 0px;
    }
    .WeAreMainContentDes p{
        font-family: 'Poppins', sans-serif;
        /* border: 1px solid black; */
        /* padding: 5px; */
        letter-spacing: 0px;
        font-size: x-small;
        /* font-size: 0.7rem; */
    }
    .WeAreMainContentImg{
        width: 170px;
        height: 170px;
        margin: auto;
    }
    
    .WeAreMainContentImg img{
        width: 170px;
        height: 170px;
    }
    
}

@media only screen and (max-width: 430px) {
    .WeAreMainContentDes{
        padding: 17vh 20px 4vh;
    }
}