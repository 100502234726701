.GenralTradingWriteUp{
    font-family: 'Poppins', sans-serif;
    width: 100%;
    margin: auto;   
    height: 100vh;
    /* margin-bottom: 25px; */
}
.GenralTradingWriteUp img{
    height: 100vh;
    width: 100%;
    object-fit: cover;
    position: absolute;
    top: 0%;
    left: 0%;
    border-radius: 10px;
}

.genralTradingStarts{
    /* padding-top: 100px; */
    background-color: rgba(0, 0, 0, 0.468);
    position: relative;
    height: 100vh;
    width: 100%;
}

.genralTradingHead{
    padding-top: 150px;
    color: white;
    text-align: center;
    /* margin-bottom: 20px; */
    font-size: 2.2rem;
}

.genralTradingInner{
    width: max-content;
    margin: auto;
    text-align: center;
    color: white;
    font-size: 1.4rem;
}

.genralTradingInner p{
    font-weight: 500;
    line-height: 7vh;
}

@media only screen and (max-width: 1110px) {
    .genralTradingInner p{
        line-height: 5vh;
        font-size: medium;
    }
}
@media only screen and (max-width: 800px) {
    .genralTradingInner p{
        line-height: 5vh;
        font-size: small;
    }
}
@media only screen and (max-width: 620px) {
    .genralTradingInner p{
        line-height: 6vh;
        font-size: 0.8rem;
    }
    .genralTradingHead{
        font-size: x-large;
    }
}
@media only screen and (max-width: 600px) {
    .genralTradingInner p{
        line-height: 6vh;
        font-size: x-small;
    }
}
@media only screen and (max-width: 490px) {
    .genralTradingInner p{
        line-height: 6vh;
        font-size: xx-small;
    }
    .genralTradingHead{
        font-size: large;
    }
}
@media only screen and (max-width: 415px) {
    .genralTradingInner p{
        line-height: 6vh;
        font-size: 0.5rem;
    }
    .genralTradingHead{
        font-size: large;
    }
}
@media only screen and (max-width: 350px) {
    .genralTradingInner p{
        line-height: 6vh;
        font-size: 0.4rem;
    }
    .genralTradingHead{
        font-size: large;
    }
}